import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 4rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: 'Montserrat-Bold';
  margin: 0 auto 0.75em;
  font-size: 30px;
`

const OurHistoryText = styled.p`
  font-family: 'Montserrat';
  font-size: 15px;
  max-width: 950px;
  margin: 1em auto;
  line-height: 1.5em;
  flex: auto;
`

export const KingCityCommunityHistory: React.FunctionComponent = () => {
  return (
    <FlexDiv style={{ flexDirection: 'column'}}>
    <OurHistoryWrapper className="ourHistoryWrapper ellicottAdvPage">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv>
          <OurHistoryTitle>The Ellicott Advantage</OurHistoryTitle>
        </FlexDiv>
        <FlexDiv className="mobileColumn" style={{ flexFlow: 'column' }}>
          <OurHistoryText style={{ flex: 1 }}>
            Ellicott Realty offers contemporary and integrated sales solutions that are delivered with precision and backed by
            a proven performance record. That’s because we understand the importance of finer detail, impeccable service
            and exceptional marketing when representing a new listing. These are key hallmarks that have helped us build a
            prestigious reputation in the real estate industry.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            We take pride in our client partnerships and continuously seek to achieve executional excellence, marked by a
            stellar collaborative experience. In fact, our commitment has allowed us to achieve over $415 million in gross sales
            since our inception in 2016.
          </OurHistoryText>
        </FlexDiv>
      </FlexDiv>
      </OurHistoryWrapper>
      
      <FlexDiv style={{ background: '#000', padding: '25px' }} className="marketingBar">
        <FlexDiv style={{ maxWidth: '80%', margin: 'auto', placeContent: 'center'}} className="marketingBarWrapper">
        <h3 style={{ color: '#fff', fontSize: '20px', borderRight: '2px solid #fff',  padding: '0 15px', margin: '0px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px'}}> Proactive  </h3>   
        <hr className="mobileOnly" />
          <h3 style={{ color: '#fff', fontSize: '20px', borderRight: '2px solid #fff', padding: '0 15px', margin: '0px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px' }}> Marketing Proficiency </h3>
          <hr className="mobileOnly" />
          <h3 style={{ color: '#fff', fontSize: '20px', borderRight: '2px solid #fff',  padding: '0 15px', margin: '0px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px' }}> Best-in Class  </h3>
          <hr className="mobileOnly" />
          <h3 style={{ color: '#fff', fontSize: '20px', padding: '0 15px', margin: '0px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px' }}> Extensive Reach </h3>
          <hr className="mobileOnly" />
        </FlexDiv>
      </FlexDiv>
      </FlexDiv>
  )
}
