import React, { useEffect, useState } from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { KingCityCommunityHistory } from 'components/KingCityCommunityHistory'
import { KingCityCommunityGeo } from 'components/KingCityCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { KingCityCommunityHero } from 'components/KingCityCommunityHero'
import { MarketingYourHome } from 'components/MarketingYourHome'
import { GlobalReach } from 'components/GlobalReach'
import { KingCityCommunityInformation } from 'components/KingCityCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchKingCity'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import { AffiliateNetwork } from 'components/AffiliateNetwork'
import Modal from 'react-modal'
import closeIcon from '../assets/icons/close-icon.svg'
import { FlexDiv } from '../design-system/FlexDiv'
import Select, { components } from 'react-select'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { Helmet } from 'react-helmet'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="description"
          content="At Ellicott Realty, we deliver white glove experience that is both trustworthy and genuine. We constantly work to exceed and redefine even the highest expectations."
        />
        <title>Ellicott Realty</title>
      </Helmet>
      <svg
        width="20"
        height="20"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        class="dropdown-heading-dropdown-arrow gray"
      >
        <path d="M6 9L12 15 18 9"></path>
      </svg>
    </components.DropdownIndicator>
  )
}

const interestedOptions = [
  {
    value: 'Detached/Single Dwelling',
    label: 'Detached/Single Dwelling',
    name: 'interestedProperties',
    type: 'text',
    id: 'interestedProperties',
  },
  {
    value: 'Semi-Detached/Townhouse',
    label: 'Semi-Detached/Townhouse',
    name: 'interestedProperties',
    type: 'text',
    id: 'interestedProperties',
  },
  {
    value: 'Condominium',
    label: 'Condominium',
    name: 'interestedProperties',
    type: 'text',
    id: 'interestedProperties',
  },
  {
    value: 'Other',
    label: 'Other',
    name: 'interestedProperties',
    type: 'text',
    id: 'interestedProperties',
  },
]

const RadioLabel = styled.label<{ for: any }>`
  font-family: 'Montserrat';
  margin: 0 0.5em;
  transform: translateY(-0.15em);
  color: black;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
`
const Label = styled.span`
  font-family: 'Montserrat';
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: white;
  -webkit-font-smoothing: antialiased;
`
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const AuroraPage: React.FunctionComponent = () => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    if (!e.target) return
    const name = e.target.name
    const value = e.target.value
  }
  type ModalIsOpen = `closed` | `opened`
  const [
    subscribeMonthlyModalisOpen,
    setsubscribeMonthlyModalisOpen,
  ] = useState<ModalIsOpen>(false)
  const handleModal = (s: ModalIsOpen) => {
    switch (s) {
      case `closed`:
        setsubscribeMonthlyModalisOpen(false)

        break
      case `opened`:
        setsubscribeMonthlyModalisOpen(true)
      default:
        break
    }
  }
  return (
    <FlexDiv>
      <EllicotLayout>
        <div style={{ textAlign: 'center' }}>
          <Modal
            isOpen={subscribeMonthlyModalisOpen}
            onRequestClose={() => handleModal('closed')}
            onAfterOpen={() => (document.body.style.overflow = 'hidden')}
            onAfterClose={scrollToTop}
            className="monthlyModal advantageContact"
          >
            <FlexDiv className="advantageModalInnerWrapper">
              <div className="advantageModalInnerWrapperClose mobileOnlyOD">
                <button
                  onClick={() => handleModal('closed')}
                  className="closeBtnImgWrapper"
                >
                  <img src={closeIcon} style={{ transform: 'none' }} />
                </button>
              </div>
              <FlexDiv
                style={{
                  flex: 50,
                }}
                className="monthlyModalContainer"
              >
                <h1 style={{ textAlign: 'center', width: '100%' }}>
                  {' '}
                  No-Obligation Home Evaluation
                </h1>
                <p style={{ textAlign: 'center', width: '100%' }}>
                  Looking to sell? Let's find out what your home is worth. Leave
                  your details below and find out the accurate value of your
                  property.
                </p>
                <form
                  name="Contact Form"
                  method="post"
                  // action="/thanks"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  style={{ width: '100%' }}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="Contact Form" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input
                        name="bot-field"
                        // onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'name'}
                      style={{ display: 'none' }}
                    >
                      Name
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'name'}
                        // onChange={this.handleChange}
                        id={'name'}
                        required={true}
                        placeholder="Enter your Name"
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingLeft: '15px',
                          border: '1px solid rgb(0,0,0)',
                          borderRadius: '3.5px',
                        }}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'email'}
                      style={{ display: 'none' }}
                    >
                      Email
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'email'}
                        name={'email'}
                        // onChange={this.handleChange}
                        id={'email'}
                        required={true}
                        placeholder="Email Address"
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingLeft: '15px',
                          border: '1px solid rgb(0,0,0)',
                          borderRadius: '3.5px',
                        }}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'phone'}
                      style={{ display: 'none' }}
                    >
                      Phone Number
                    </label>
                    <NumberFormat
                      name="phone"
                      placeholder={'Phone number'}
                      format="(###) ###-####"
                      label="Phone Number"
                      className="contactFormInput no-zoom"
                      // onChange={this.handleChange}
                      type={'phone'}
                      id={'phone'}
                      style={{
                        marginRight: 5,
                        background: '#fff',
                        color: '#000',
                        border: '1px solid lightgray',
                        height: '40px',
                        paddingLeft: '15px',
                        width: '100%',
                      }}
                    />
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'propertyAddress'}
                      style={{ display: 'none' }}
                    >
                      Property Address
                    </label>
                    <div className="control">
                      <input
                        className="input no-zoom"
                        type={'text'}
                        placeholder={'Property Address'}
                        name={'propertyAddress'}
                        // onChange={this.handleChange}
                        id={'propertyAddress'}
                        required={true}
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingLeft: '15px',
                          border: '1px solid rgb(0,0,0)',
                          borderRadius: '3.5px',
                        }}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'city'}
                      style={{ display: 'none' }}
                    >
                      City
                    </label>
                    <div className="control">
                      <input
                        className="input no-zoom"
                        type={'city'}
                        placeholder={'City'}
                        name={'city'}
                        // onChange={this.handleChange}
                        id={'city'}
                        required={true}
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingLeft: '15px',
                          border: '1px solid rgb(0,0,0)',
                          borderRadius: '3.5px',
                          marginTop: '10px',
                        }}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'interestedProperties'}
                      style={{ display: 'none' }}
                    >
                      Interested Properties
                    </label>
                    <Select
                      options={interestedOptions}
                      name={'interestedProperties'}
                      type={'interestedProperties'}
                      id={'interestedProperties'}
                      placeholder="Type of Property"
                      // onChange={handleSelectChange}
                      className="signUpDropDownWidth hundoWidth advantageForm"
                      classNamePrefix="filter"
                      components={{ DropdownIndicator }}
                      style={{
                        height: '40px',
                        width: '100%',
                        paddingLeft: '15px',
                        border: '1px solid rgb(0,0,0)',
                        borderRadius: '3.5px',
                        marginTop: '10px',
                      }}
                    />
                  </div>
                  <div className="field">
                    <label
                      className="label"
                      htmlFor={'postalCode'}
                      style={{ display: 'none' }}
                    >
                      Postal Code
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'postalCode'}
                        placeholder={'Postal Code'}
                        name={'postalCode'}
                        // onChange={this.handleChange}
                        id={'postalCode'}
                        required={true}
                        style={{
                          height: '40px',
                          width: '100%',
                          paddingLeft: '15px',
                          border: '1px solid rgb(0,0,0)',
                          borderRadius: '3.5px',
                        }}
                      />
                    </div>
                  </div>
                  <FlexDiv>
                    <Label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '7.5px',
                        alignItems: 'flex-start',
                      }}
                      className="underContractRealtor"
                    >
                      Are you currently under a contract with a REALTOR?
                    </Label>
                  </FlexDiv>
                  <div
                    className="contactInputWrapper"
                    onChange={handleChange}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 7.5px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      <input
                        type="radio"
                        id="yes"
                        name={'areYouWithARealtor'}
                        checked={'yes'}
                        value={'yes'}
                      />
                      <RadioLabel for="yes" style={{ fontSize: '13.5px' }}>
                        Yes
                      </RadioLabel>
                    </div>
                    <div
                      style={{ marginTop: '' }}
                      className="radioLabelRightSide"
                    >
                      <input
                        type="radio"
                        id="no"
                        name={'areYouWithARealtor'}
                        checked={'no'}
                        value={'no'}
                        style={{ marginTop: 0 }}
                      />
                      <RadioLabel for="no" style={{ fontSize: '13.5px' }}>
                        No
                      </RadioLabel>
                    </div>
                  </div>

                  <div
                    className="field"
                    style={{ textAlign: 'left', marginTop: '7.5px' }}
                  >
                    <button className="button is-link" type="submit">
                      Next
                    </button>
                  </div>
                </form>
              </FlexDiv>
              <FlexDiv className="monthlyModalCloseWrapper desktopOnly">
                <button
                  onClick={() => handleModal('closed')}
                  className="closeBtnImgWrapper"
                >
                  <img src={closeIcon} />
                </button>
              </FlexDiv>
            </FlexDiv>
          </Modal>
        </div>
        <div className="advantageCTAWrapper">
          <div
            className="advantageCTAForm"
            onClick={() => handleModal('opened')}
          >
            HOME EVALUATION
          </div>
        </div>
        <KingCityCommunityHero />
        <KingCityCommunityHistory />
        <KingCityCommunityInformation />
        <MarketingYourHome />
        <KingCityCommunityGeo />

        <GlobalReach />
        <AffiliateNetwork />
      </EllicotLayout>
    </FlexDiv>
  )
}

export default AuroraPage
