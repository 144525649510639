import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { LoginComponentProps } from 'components/LoginComponent'
import { SignupComponentProps } from 'components/SignUp'
import propDetails from '../images/global-reach.jpg'
import globalReachMobile from '../images/mobile-global-reach.png'
import { services } from '@services'

const Links = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.3px;
  color: #ffffff;
  @media (max-width: 1515px) {
    font-size: 15px;
  }
  @media (max-width: 1360px) {
    font-size: 14px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1100px) {
    font-size: 11.5px;
  }
`

const PreffedSignup = styled.button`
  color: #fff;
  border: 1.5px solid #fff;
  padding: 15px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-size: 0.7em;
  width: 100%;
  background: transparent;
  margin-top: 2em;
  font-weight: 600;
`

const StyledCard = styled.div`
    height:300px
    background: gray
`

const DivWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2em 0 5em;
`

const CardTitle = styled.h3`
  font-family: 'CentraNo2-Bold';
  -webkit-font-smoothing: antialiased;
  margin: 1em 2em;
`

const CardTitle2 = styled.h3`
  font-family: 'CentraNo2-Bold';
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 2em 0 0.5em;
`

const CardSubTitle = styled.p`
  font-family: 'CentraNo2-Light';
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
`

const IconRightHack = styled.div`
  align-self: flex-end;
  margin: 1em;
`

const SubText = styled.p`
  font-family: 'CentraNo2-Book';
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
`

const InputFieldTemp = styled.div`
  font-family: 'CentraNo2-Light';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #fff;
  width: 100%;
  padding: 10px;
`

const SignUpBtn = styled.div`
  text-align: center;
  font-family: CentraNo2-Book;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000;
  padding: 10px;
  font-size: 0.9em;
`

const FeaturedTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const PreferredSubText = styled.p`
  font-family: CentraNo2-Book;
  font-weight: 100;
  font-size: 0.8em;
`

const IconImg = styled.img`
  height: 2em;
`

export const GlobalReach: React.FunctionComponent<HeaderPropsType> = props => {
  const [loginIsOpen, setLoginIsOpen] = React.useState(false)
  const [signupIsOpen, setSignupIsOpen] = React.useState(false)

  const openLogin = () => {
    setLoginIsOpen(true)
  }

  const closeLogin = () => {
    setLoginIsOpen(false)
  }

  const openSignup = () => {
    setSignupIsOpen(true)
  }

  const closeSignup = () => {
    setSignupIsOpen(false)
  }

  const loginUser: LoginComponentProps['handleLoginUser'] = ({
    credentials,
    rememberMe,
  }) => {
    services.firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(closeLogin)
  }
  const signupUser: SignupComponentProps['handleSignupUser'] = formData => {
    services.firebase
      .auth()
      .createUserWithEmailAndPassword(formData.email, formData.password)
    services.lifted.sendRegisterForm(formData)
  }

  return (
    <FlexDiv style={{ paddingTop: '12em'}} className="globalReachWrapper">
      <FlexDiv className="propDetailsFlex" style={{ height: '350px', maxHeight: '350px', flex: '1.1'}}>
        <img src={propDetails} className="propDetailsImgSell global desktopOnly" style={{width: '80%', marginLeft: '20.25%', objectFit: 'cover'}}/>
        <img src={globalReachMobile} className="propDetailsImgSell global mobileOnly" style={{width: '70%', marginLeft: '20.25%', objectFit: 'cover'}}/> 
      </FlexDiv>
      <FlexDiv style={{ background: '#000', placeContent: 'flex-start' }} className="propDetailsTextWrapper">
        <h2 className="propDetailsTitle"> Global and Local Reach</h2>
        <FlexDiv className="propDetailsText">
        <p style={{ color: '#fff'}}>
          All of our listings are distributed on top real estate websites like Realtor.ca, Zillow.com, GlobalListings.com, and Zolo.ca.
        </p>
        <p style={{ color: '#fff'}}>
            Our listings are distributed through 105+ portals and websites worldwide with over 164 million monthly unique visitors in 61 countries all over the world. With this global presence and local focus, we can ensure listings have optimal reach and impact.
        </p>
          </FlexDiv>
      </FlexDiv>
  </FlexDiv>
  )
}
