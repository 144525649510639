import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import affiliateNetwork from '../images/affiliate-network.png'
import portalImg from '../images/portals.png'
import realtorca from '../images/realtorca.png'
import zolo from '../images/zolo.png'
import zillo from '../images/zillow.png'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 950px;
  margin: auto;
  padding: 4rem;
  padding-top: 70px !important;
  padding-bottom: 70px !important;
`

const OurHistoryTitle = styled.h3`
  font-family: 'Montserrat-Bold';
  font-size: 30px;
`

const OurHistoryText = styled.p`
  font-family: 'Montserrat';
  line-height: 1.5;
  font-size: 0.95em;
  letter-spacing: 0.5px;
`

export const AffiliateNetwork: React.FunctionComponent = () => {
  return (
    <FlexDiv>
    <FlexDiv className="affiliatesNetwork">
    <OurHistoryWrapper className="ourHistoryWrapper">
      <FlexDiv style={{ flexFlow: 'column' }} className="">
        <FlexDiv className="informationImgWrapper mobileColumn">
          
          <FlexDiv
            style={{
              flex: 1,
              flexDirection: 'column',
              placeSelf: 'center',
            }}
          >
                <OurHistoryTitle style={{ marginTop: 0, marginBottom: '0.25em' }}
                  className="affiliateMainTitle"
                >
              Affiliate Network
            </OurHistoryTitle>
            <FlexDiv>
              <OurHistoryText style={{ maxWidth: '87.5%', fontSize: '15px'}}>
                Plus, with distribution in top markets, we can also amplify your listing in Homes & Land magazine, with a hyper-focus on streamlining the home buying process by presenting your listings to a captive audience with real home buying intent.
              </OurHistoryText>
            </FlexDiv>
          </FlexDiv>
              <FlexDiv style={{ flex: 1, alignContent: 'center', justifyContent: 'center', transform: 'translateX(15%)' }} className="affiliateNetworkImgWrapper">
              <img
                src={affiliateNetwork}
                style={{ maxWidth: '62.5%', objectFit: 'contain' }}
              />
          </FlexDiv>
        </FlexDiv>
        
      </FlexDiv>
      
    </OurHistoryWrapper>
    <FlexDiv style={{ background: '#000', padding: '17.5px', flex: 'auto' }} className="ctaPortalsCountries">
        <FlexDiv style={{ margin: 'auto', placeContent: 'center'}} >
        
        <h3 style={{ color: '#fff', fontSize: '30px', borderRight: '2px solid #fff', padding: '0 15px', margin: '0 10px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px'}}> 105 Portals </h3>   
        <hr className="mobileOnly" />

         <h3 style={{ color: '#fff', fontSize: '30px', borderRight: '2px solid #fff', padding: '0 15px', margin: '0 10px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px'}}> 61 Countries </h3>
        <hr className="mobileOnly" />

        <h3 style={{ color: '#fff', fontSize: '30px', padding: '0 15px', margin: '0 10px', fontFamily: 'Montserrat-Bold', letterSpacing: '0.5px'}}> 164.01M Potential Buyers </h3>
        <hr className="mobileOnly" />

        </FlexDiv>
      </FlexDiv>
      
    </FlexDiv>
      <div style={{ textAlign: 'center', padding: '50px 0 0'}}>
        <h3 className="extensive-reach">Extensive Reach</h3>
        <p className="portalTextP" >We have taken the necessary steps to position our listings within clear view of potential prospects who reside locally, nationally, and internationally.</p>
        <img src={portalImg} style={{
          width: '85%', margin: 'auto', padding: '2rem 0 4rem', margin: '0 7em',
        }}
        className="portalsImg"/>
      </div>
      <FlexDiv style={{ borderTop: '3px solid #000', borderBottom: '3px solid #000'}}>
        <FlexDiv style={{ maxWidth: '85%', margin: 'auto', placeContent: 'center' }} className="topCanadianSitesWrapper">
          <h1 style={{ display: 'flex', flex: '2', fontFamily: 'Montserrat-Bold'}}> Top Canadian Sites</h1>
          <FlexDiv style={{ placeItems: 'center' }}>
            <img src={realtorca} style={{ height: '45px' }} />
            <h2 style={{ fontFamily: 'Montserrat-Bold', fontSize: '16px', marginLeft: '10px' }}> <a href="Realtor.ca" style={{ fontFamily: 'inherit', fontWeight: 'inherit', color: 'inherit', textDecoration: 'none'}}> Realtor.ca</a></h2>
          </FlexDiv>
          <FlexDiv style={{ placeItems: 'center' }}>
            <img src={zolo} style={{ height: '45px' }} />
            <h2 style={{ fontFamily: 'Montserrat-Bold', fontSize: '16px', marginLeft: '10px' }}><a href="Zillow.ca" style={{ fontFamily: 'inherit', fontWeight: 'inherit', color: 'inherit', textDecoration: 'none'}}>Zillow.ca </a></h2>
          </FlexDiv>
          <FlexDiv style={{ placeItems: 'center' }}>
            <img src={zillo} style={{ height: '45px' }} />
            <h2 style={{ fontFamily: 'Montserrat-Bold', fontSize: '16px', marginLeft: '10px' }}><a href="Zolo.ca" style={{ fontFamily: 'inherit', fontWeight: 'inherit', color: 'inherit', textDecoration: 'none'}}>Zolo.ca </a></h2>
            </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  )
}
