import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import auroraDowntown from '../images/boutqiueExperience.jpg'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 82.5%;
  margin: auto;
  padding: 2rem 5rem;
`

const OurHistoryTitle = styled.h3`
  font-family: 'Montserrat-Bold';
  font-size: 30px;
`

const OurHistoryText = styled.p`
  font-family: 'Montserrat';
  line-height: 1.5;
  font-size: 0.95em;
`

export const KingCityCommunityInformation: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper boutiqueExperience">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv className="informationImgWrapper">
          <FlexDiv style={{ flex: 1 }} >
            <div className="boutiqueImgWrapper">
              <img
                src={auroraDowntown}
                style={{ width: '90%', height: '90%', objectFit: 'contain' }}
              />
            </div>
          </FlexDiv>
          <FlexDiv
            style={{
              flex: 2,
              flexDirection: 'column',
              placeSelf: 'center',
            }}
            className="boutiqueTextWrapper"
          >
            <OurHistoryTitle style={{ marginTop: 0, marginBottom: 0 }}>
              Boutique Experience
            </OurHistoryTitle>
            <FlexDiv>
              <OurHistoryText>
                <em> Ellicott Realty </em> is a boutique brokerage specializing in a variety of real estate sectors, including residential, commercial, land development, retail, industrial and investment. Our team is well established in York Region and surrounding markets, making us the pre-eminent experts for reaching the GTA’s multi-faceted suburbs and neighbourhood communities.
              </OurHistoryText>
            </FlexDiv>
            <OurHistoryText style={{ flex: 1 }}>
              We appreciate that every end consumer is unique and proactively tailor our approach to meet their needs. Whether buying a first home, or expanding an investment portfolio, we employ persuasive sales tactics that are customized based on what consumers truly wish to accomplish with their purchase.
            </OurHistoryText>
            <OurHistoryText style={{ flex: 1, paddingBottom: '2em'}}>
            Our size allows us to deliver a white glove experience that is both trustworthy and genuine. We constantly work to exceed and redefine even the highest expectations. With a knowledge pipeline that spans the globe, we are consistently able to evolve our approach to implement leading-edge sales solutions locally.
            </OurHistoryText>

            
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
