import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import yardSignage from '../images/yard-new123.png'
import mediaActivation from '../images/paid-media.png'
import propertyBrochure from '../images/property-brochure-new.png'
import propertyListing from '../images/property-listing.png'
import activeProspecting from '../images/active-propsecting.jpg'
import professionalImages from '../images/professional-images.jpg'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`

`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
  margin: 0 1em;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 2em 1em;
`

export const KingCityCommunityGeo: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="commGeoWrapper">
      <FlexDiv style={{ flexFlow: 'column',  }}>
        <FlexDiv>
          
        </FlexDiv>
        <FlexDiv className="mobileColumnReverse yardSignageWrapper mediaActivations leftImg profImg">
          <FlexDiv style={{ flex: 1, alignSelf: 'flex-start' }}>
            <img src={professionalImages} style={{ maxWidth: '90%', margin: ''}}/>
          </FlexDiv>
          <FlexDiv style={{ flex: 1, placeSelf: 'center' }}>
          <h2 style={{ fontSize: '30px', fontFamily: 'Montserrat-Bold'}} className="profImagesTitle"> Professional Images:</h2>
            <p>
              Professional quality imagery can mean the difference between making a sale or not.
          </p>
            <p style={{ paddingBottom: '1em'}}>
              We understand what it takes to compose remarkable, eye-catching images. Each of our listings include a professional photography package, optimized for print and digital use, on your home’s web page, and any other digital platforms. Our professional photographers will capture pristine images that showcase your home in the best way possible.
            </p>
            </FlexDiv>
        </FlexDiv>
        <FlexDiv className="mobileColumn  yardSignageWrapper mediaActivations rightImg activeProspecting">
          
          <FlexDiv style={{ flex: 1, placeSelf: 'center' }}>
          <h2 style={{ fontSize: '30px', fontFamily: 'Montserrat-Bold'}}> Active Prospecting:</h2>
            <p>
              Including cold calling and online marketing, all of our agents actively prospect to ensure they have a steady client list. Plus, we have an extensive database of potential and existing clients that we stay in contact with regularly.
          </p>
            <p>
              Our proactive approach includes a variety of strategies that ensure your home is well represented, including emailing top local agents, utilizing our extensive database of potential clients, daily prospecting for qualified buyers, internal and external networking with agents, direct mailers, social media activations, open house events, and more.
            </p>
          </FlexDiv>
          <FlexDiv style={{ flex: 1 }}>
                <img src={activeProspecting} style={{ maxWidth: '90%', margin: ''}}/>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv className="mobileColumnReverse  yardSignageWrapper mediaActivations leftImg listingWebsite">
          <FlexDiv style={{ flex: 1 }}>
                <img src={propertyListing} style={{ maxWidth: '57.5%', marginLeft: '15%'}} className="listingWebsiteSecImg"/>
          </FlexDiv>
          <FlexDiv style={{ flex: 1, placeSelf: 'center' }}>
          <h2 style={{ fontSize: '30px', fontFamily: 'Montserrat-Bold'}}> Listing Website:</h2>
            <p>
              Each of our listings include their very own professionally designed landing page. Complete with property features, an elegant gallery of professional photos, plus a virtual video tour. We also include a neighbourhood analysis, comprehensively designed to be amplified online to reach highly-targeted potential buyers via Google, social media, and local websites.
          </p>
            <p>
              An effective landing page is optimized to present your home, collect interested visitors information, and support our real estate marketing strategy. Your property landing page will be used to help stimulate interest, qualify potential prospects, and encourage an active response.
            </p>
            </FlexDiv>
        </FlexDiv>
        <FlexDiv className="mobileColumn yardSignageWrapper mediaActivations rightImg propBrochure">
          
          <FlexDiv style={{ flex: 1, placeSelf: 'center' }}>
          <h2 style={{ fontSize: '30px', fontFamily: 'Montserrat-Bold'}}> Property Brochure:</h2>
            <p>
             Featuring a modern and clean layout, stunning photography, and purposefully written property descriptions, our brochures are designed to sell. Our feature sheets, postcards, door hangers, and brochures are meticulously prepared to display your home with the attention it deserves.
            </p>
          </FlexDiv>
          <FlexDiv style={{ flex: 1 }}>
              <img src={propertyBrochure} style={{ maxWidth: '60%', margin: 'auto', objectFit: 'contain'}} className="propBrochureImg"/>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv className="mobileColumnReverse yardSignageWrapper mediaActivations leftImg mediaActivationsSec">
          <FlexDiv style={{ flex: 1 }}>
              <img src={mediaActivation} style={{ maxWidth: '60%', marginLeft: '15%'}} className="mediaActivationsImgWrapper"/>
          </FlexDiv>
          <FlexDiv style={{ flex: 1, placeSelf: 'center' }}>
            <FlexDiv>
          <h2 style={{ fontSize: '30px', fontFamily: 'Montserrat-Bold'}}> Media Activations:</h2>
            </FlexDiv>
              <p>
              Featuring data-driven targeting, compelling copy, and stellar photography, we can position your home beyond the usual to create the most powerful opportunity to stimulate response.
          </p>
            <p>
              Through our social media activations, your home will be at the center of it all. Our agents have the opportunity to amplify your listing to maximize reach and enhance networking opportunities amongst potential buyers and realtors.
            </p>
            <p>
              Our campaigns go further than simply posting your home on our social channels.
            </p>
            <p>
              We leverage unique technologies that intelligently and algorithmically identify highly-qualified potential buyers, to make sure your property is seen by those most likely to be interested in your home.
            </p>
            </FlexDiv>
        </FlexDiv>
        <FlexDiv className="mobileColumn yardSignageWrapper yardSignageSec rightImg">
          
          <FlexDiv style={{ flex: 1, placeSelf: 'center' }}>
          <h2 style={{ fontSize: '30px', fontFamily: 'Montserrat-Bold'}}> Yard Signage: </h2>
            <p>
              In this digital era, you may be skeptical about the importance of having a physical sign in front of your home. Although potential buyers usually find your home on MLS and other online mediums, the impact of physical signage should not be ignored.
            </p>
            <p>
              Professionally designed and printed on high-quality reflective vinyl, our yard signs are an extension of your online visibility. Let your neighbours know you're being represented by the very best!
            </p>
          </FlexDiv>
          <FlexDiv style={{ flex: 1 }}>
            <img src={yardSignage} style={{ maxWidth: '90%', margin: 'auto'}} className="yardImg"/>
          </FlexDiv>
        </FlexDiv>
        </FlexDiv>
      
    </OurHistoryWrapper>
  )
}
