import React from 'react'
import { FlexDiv } from 'design-system/FlexDiv'
import Searchfor from '../images/collage.jpg'
import SearchforMobile from '../images/collage-mobile.jpg'
import styled from 'styled-components'
import { GeocoderLocation } from './GeocodeInput'

const IconDiv = styled.button`
  height: 100%;
  width: 10%;
  font-family: CentraNo2-Book;
  display: flex;

  background: white;
  border: 1px solid gray;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const StartSearchTitle = styled.h1`
  font-family: 'CentraNo2-Bold';
  font-size: 30px !important;
  letter-spacing: 1px;
`

const SearchForm = styled.form`
  width: 90%;
  display: flex;
`

export const MarketingYourHome: React.FC<{
  onSearch: (loc: GeocoderLocation) => void
}> = props => {
  const [searchLocation, setSearchLocation] = React.useState<GeocoderLocation>()

  const handleSearchFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchLocation && props.onSearch) {
      props.onSearch({
        position: searchLocation.position,
        bounds: searchLocation.bounds,
        label: searchLocation.label,
      })
    }
  }
  return (
    <FlexDiv style={{ paddingTop: '3em', paddingBottom: '35px' }} className="marketYourHomeWrapper">
      <FlexDiv className="lookToBuySec">
        <FlexDiv className="lookingToBuy advantage">

          <div>
            <h2 style={{ marginBottom: '1.5em', fontSize: '30px', fontFamily: 'Montserrat-Bold' }}> Marketing Your Home</h2>
          </div>
          <FlexDiv
            style={{
              flex: 2,
            }}
            className="mobileFlexColumn"
          >
            <FlexDiv className="mobileFlexColumn posRelative">
              <FlexDiv className="searchWrapper searchMobileImgWrapper2" style={{
                background: '#000',
                color: 'white',
                padding: '2em 0'
              }}>
                <p style={{ fontSize: '15px', maxWidth: '62.5%', marginTop: '0px !important' }}>
                  Our marketing department is focused on devising a powerful campaign that’s built on insightful strategy, innovative content creation, plus effective media and digital amplification.
                </p>
                <p style={{ fontSize: '15px', maxWidth: '62.5%', paddingBottom: '' }}>
                  It’s all fueled by a thorough planning
                  process which begins with market
                  insights and research, target audience
                  and competitive analysis – both online and offline. From here, we develop compelling creative to drive awareness and stimulate sales. Powered by in-house experts, we manage full content creation, including
                  promotion and incentives, copy and visuals, plus media buying and production. No matter the media mix, we’ll maximize reach for a 360° campaign that delivers powerful results.
                </p>
              </FlexDiv>
              <FlexDiv style={{ flex: 1.3, width: '50%', background: '#000', color: '#fff' }} className="searchMobileImgWrapper">

                <img src={SearchforMobile} className="searchFor marketingYourHomeImg mobileOnly" />
                <img src={Searchfor} className="searchFor marketingYourHomeImg desktopOnly" />
              </FlexDiv>

            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  )
}
